<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-2banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">市场渗透率分析</div>
            <div class="head-explainBox-content1">全球某化妆品巨头</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">经过二十多年的快速发展，客户公司销售区域遍布全国。当市场规模扩大以后，使用销售额来衡量各区域业
                务经营状况的方式无法满足市场发展需求，需要基于数据分析平台和算法体系的支撑，建立一套适合行业业务特点的全新市场衡量指标。同时，落地运
                营级的销售管理系统，也需要借助成熟的解决方案。渗透率已经成为驱动市场份额增长最重要的因素之一，成功的快消和零售企业都在努力逐个地区的
                提高当地渗透率。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem"><span class="title">需求场景</span></div>
            <div class="head-list-item"><span class="title">实施方案</span></div>
            <div class="head-list-item"><span class="title">方实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">需求场景</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">难以制订合理指标判定地区业务好坏</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">不同地区的市场存在差异，销售队伍难以找到对标，复制成功经验</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">行政区域与销售区域未能精准匹配，线下推广活动具有复杂度</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">较难评估区域业务饱和度与发展潜力值</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">新的管理思路未能形成指标体系与具体操作，需进一步落地，让管理可执行</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">数据分析与销售策略未能做到端到端打通，分析结果无法直接指导销售</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 84rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../../assets/image/selected/03-2-2实施方案.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2销售管理.svg"/>
                        <div>
                            <div class="title1">销售管理</div>
                            <div class="title2">建立渗透率指标体系，精准判定地区业务好坏</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2业务评估.svg"/>
                        <div>
                            <div class="title1">业务评估</div>
                            <div class="title2">评估业务发展潜力，成功预测业务回升拐点</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2区域运营.svg"/>
                        <div>
                            <div class="title1">区域运营</div>
                            <div class="title2">精准划分城市区域，指导实际业务运营，降低企业推广成本</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2销售预测.svg"/>
                        <div>
                            <div class="title1">销售预测</div>
                            <div class="title2">预测区域业务发展潜力，指导业务发展方向，提高业务销售业绩</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2业务发展.svg"/>
                        <div>
                            <div class="title1">业务发展</div>
                            <div class="title2">为销售队伍找到对标，复制成功经验，指导经销商业务开发</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-2管理工具.svg"/>
                        <div>
                            <div class="title1">管理工具</div>
                            <div class="title2">建立渗透率管理工具，提高了企业管理者、区域经理以及首席经销商的管理能力</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: left;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: left;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.8rem;
    height: 71.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 17.8rem;
        height: 20rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: left;
            width: 14.6rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #7A3C8F;
    }
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;

    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 3.3rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 0.8rem 0 0 1.2rem;
        }
    }
}
</style>
